import React from "react";
import Friend from "../../assets/Friends.svg";
import Image4 from "../../assets/Image4.svg";
import Sections from "../../assets/Sections.svg";

export default function Frynds() {
	return (
		<section className='py-6'>
			<div className='container mx-auto'>
				<div className='flex flex-col-reverse md:flex-row'>
					<div className='bg-white shadow-lg rounded-3xl md:px-20 px-5 pt-20 relative md:w-[65%] md:mr-14 md:mt-0 mt-6'>
						<h2 className='md:text-6xl text-4xl font-semibold mb-8 text-mainTitle'>
							From your{" "}
							<span className='text-transparent bg-gradient-to-r from-linerOne/100 to-linerTwo/100 bg-clip-text'>
								Fryends,
							</span>
						</h2>
						<h2 className='md:text-6xl text-4xl mb-14 text-allLead'>
							we generate leads
						</h2>
						<img src={Image4} alt='Image4' />

						<div className='absolute top-14 right-14'>
							<img src={Friend} alt='Friend' />
						</div>
					</div>

					<div className='md:w-[35%]'>
						<div className='bg-white shadow-lg rounded-3xl md:px-16 px-5'>
							<img
								src={Sections}
								alt='Sections.svg'
								className='md:ml-auto mx-auto'
							/>
						</div>

						<div className='bg-gradient-to-r from-[#FCBD99] to-[#F1E1C2] mt-12 rounded-3xl py-12 px-12 shadow-md'>
							<h2 className='md:text-5xl text-4xl font-semibold text-white'>
								From hot leads to{" "}
								<span className='text-transparent bg-gradient-to-r from-linerOne/100 to-linerTwo/100 bg-clip-text'>
									closed deals.
								</span>
							</h2>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}
