import React, { useState } from "react";
import Logo from "../../assets/Logo.svg";
function NavBar() {
	const [nav, setNav] = useState(false);

	const navSet = () => {
		setNav(!nav);
	};

	if (nav) {
		document.body.classList.add("overflow-y-hidden");
	} else {
		document.body.classList.remove("overflow-y-hidden");
	}

	return (
		<nav className='py-10'>
			<div className='container mx-auto'>
				<div className='flex items-center justify-between'>
					<div>
						<img src={Logo} alt='Logo' />
					</div>

					<div>
						<ul className='md:flex items-center hidden'>
							<li>
								<a href='#!' className='text-linkF text-leadColor font-bold'>
									Home
								</a>
							</li>
							<li className='ml-8'>
								<a
									href='#!'
									className='text-linkF text-headerSubClr font-bold transition-all duration-300 ease-in-out hover:text-leadColor'
								>
									The Tech
								</a>
							</li>
							<li className='ml-8'>
								<a
									href='#!'
									className='text-linkF text-headerSubClr font-bold transition-all duration-300 ease-in-out hover:text-leadColor'
								>
									Contact
								</a>
							</li>
							<li className='ml-9'>
								<button className='bg-btnBack text-white text-linkF font-medium py-3 px-8 rounded-md'>
									Subscribe
								</button>
							</li>
						</ul>
					</div>

					<div className='block md:hidden z-10'>
						{!nav ? (
							<svg
								width='19'
								height='16'
								viewBox='0 0 19 16'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
								onClick={navSet}
							>
								<path
									d='M0.175781 1C0.175781 0.447715 0.623497 0 1.17578 0H17.1758C17.7281 0 18.1758 0.447715 18.1758 1C18.1758 1.55228 17.7281 2 17.1758 2H1.17578C0.623497 2 0.175781 1.55228 0.175781 1ZM6.17578 8C6.17578 7.44772 6.6235 7 7.17578 7H17.1758C17.7281 7 18.1758 7.44772 18.1758 8C18.1758 8.55229 17.7281 9 17.1758 9H7.17578C6.6235 9 6.17578 8.55229 6.17578 8ZM0.175781 15C0.175781 14.4477 0.623497 14 1.17578 14H17.1758C17.7281 14 18.1758 14.4477 18.1758 15C18.1758 15.5523 17.7281 16 17.1758 16H1.17578C0.623497 16 0.175781 15.5523 0.175781 15Z'
									fill='#010101'
								/>
							</svg>
						) : (
							<svg
								onClick={navSet}
								xmlns='http://www.w3.org/2000/svg'
								fill='none'
								viewBox='0 0 24 24'
								strokeWidth='1.5'
								stroke='currentColor'
								class='w-6 h-6'
							>
								<path
									strokeLinecap='round'
									strokeLinejoin='round'
									d='M4.5 19.5l15-15m-15 0l15 15'
								/>
							</svg>
						)}
					</div>

					<div
						className={
							nav
								? "fixed md:hidden top-[15%] right-1/2 transform translate-x-1/2 bg-white h-auto w-[80%] transition-all rounded-lg duration-300 ease-in-out"
								: "fixed md:hidden top-[15%] -right-full bg-white h-auto w-[80%] transition-all duration-300 ease-in-out rounded-lg"
						}
					>
						<ul className='flex flex-col py-10 px-5'>
							<li className='ml-3'>
								<a
									href='#!'
									className='text-linkF text-leadColor font-bold inline-block mb-5'
									onClick={navSet}
								>
									Home
								</a>
							</li>
							<li className='ml-3'>
								<a
									href='#!'
									className='text-linkF text-headerSubClr font-bold transition-all duration-300 ease-in-out hover:text-leadColor inline-block mb-5'
									onClick={navSet}
								>
									The Tech
								</a>
							</li>
							<li className='ml-3'>
								<a
									href='#!'
									className='text-linkF text-headerSubClr font-bold transition-all duration-300 ease-in-out hover:text-leadColor inline-block mb-5'
									onClick={navSet}
								>
									Contact
								</a>
							</li>
							<li className='ml-3'>
								<button
									onClick={navSet}
									className='bg-btnBack text-white text-linkF font-medium py-3 px-8 rounded-md inline-block mb-5 shadow-md'
								>
									Subscribe
								</button>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</nav>
	);
}

export default NavBar;
