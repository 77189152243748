import React from "react";
import Chat from "../../assets/Chat.svg";
import Halfphone from "../../assets/HalfIphone.svg";

export default function Intro() {
	return (
		<section className='py-9'>
			<div className='container mx-auto'>
				<div className='bg-white shadow-lg rounded-3xl md:px-20 px-5 pt-20 relative'>
					<div className='flex md:flex-row flex-col items-center'>
						<div className='col-span-2 text-right'>
							<h2 className='md:text-6xl text-4xl font-semibold mb-8 text-mainTitle'>
								Strong warm{" "}
								<span className='text-transparent bg-gradient-to-r from-linerOne/100 to-linerTwo/100 bg-clip-text'>
									introductions
								</span>
							</h2>
							<h2 className='md:text-6xl text-4xl mb-14 text-allLead'>
								via unique three-way chatrooms.
							</h2>
						</div>
						<div className='md:ml-auto'>
							<img src={Halfphone} alt='Halfphone' />
						</div>
					</div>

					<div className='absolute top-14 right-14'>
						<img src={Chat} alt='Chat' />
					</div>
				</div>
			</div>
		</section>
	);
}
