import Frynds from "./components/Fryends/Frynds";
import Hero from "./components/Hero/Hero";
import Intro from "./components/Intro/Intro";
import NavBar from "./components/NavBar/NavBar";
import Sliders from "./components/Sliders/Sliders";
import Subscribe from "./components/Subscribe/Subscribe";
import Videos from "./components/Videos/Videos";
import Cards from "./components/Cards/Card";
import Questions from "./components/Questions/Questions";
import Discover from "./components/Discover/Discover";
import Footer from "./components/Footer/Footer";

function App() {
	return (
		<>
			<NavBar />
			<Hero />
			<Subscribe />
			<Intro />
			<Frynds />
			<Sliders />
			<Videos />
			<Cards />
			<Questions />
			<Discover />
			<Footer />
		</>
	);
}

export default App;
