import React from "react";
import Img1 from "../../assets/Circle.svg";
import Img2 from "../../assets/Circle2.svg";
import Img3 from "../../assets/Users.svg";

function Card() {
	return (
		<section className='py-12'>
			<div className='container mx-auto'>
				<div className='grid grid-cols-1 md:grid-cols-3 gap-24'>
					<div className='text-center md:px-20 px-10'>
						<img src={Img1} alt='Img1' className='mx-auto mb-5' />
						<h4 className='text-xl font-bold text-playTxt mb-3'>
							Auto-Referrals
						</h4>
						<p className='text-cards'>
							Fryends constantly scours your network looking for jobs that match
							your skills.
						</p>
					</div>
					<div className='text-center  md:px-20 px-10'>
						<img src={Img2} alt='Imgs' className='mx-auto mb-5' />
						<h4 className='text-xl font-bold text-playTxt mb-3'>Leads</h4>
						<p className='text-cards'>
							Fryends constantly scours your network looking for jobs that match
							your skills.
						</p>
					</div>
					<div className='text-center  md:px-20 px-10'>
						<img src={Img3} alt='Imgs' className='mx-auto mb-5' />
						<h4 className='text-xl font-bold text-playTxt mb-3'>
							Introductions
						</h4>
						<p className='text-cards'>
							Fryends constantly scours your network looking for jobs that match
							your skills.
						</p>
					</div>
				</div>
			</div>
		</section>
	);
}

export default Card;
