import { Fragment, useState } from "react";
import {
	Accordion,
	AccordionHeader,
	AccordionBody,
} from "@material-tailwind/react";

function Icon({ id, open }) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			className={`${
				id === open ? "rotate-180" : ""
			} h-5 w-5 transition-transform`}
			fill='none'
			viewBox='0 0 24 24'
			stroke='currentColor'
			strokeWidth={2}
		>
			<path strokeLinecap='round' strokeLinejoin='round' d='M19 9l-7 7-7-7' />
		</svg>
	);
}

function Questions() {
	const [open, setOpen] = useState(0);

	const handleOpen = (value) => {
		setOpen(open === value ? 0 : value);
	};

	return (
		<section>
			<div className='container mx-auto'>
				<div className='bg-white shadow-lg rounded-3xl md:px-20 px-10 pt-20'>
					<h2 className='md:text-6xl text-4xl font-semibold mb-8 text-mainTitle'>
						Frequently{" "}
						<span className='text-transparent bg-gradient-to-r from-linerOne/100 to-linerTwo/100 bg-clip-text'>
							asked...
						</span>
					</h2>

					<Fragment>
						<Accordion
							open={open === 1}
							icon={<Icon id={1} open={open} />}
							onClick={() => handleOpen(1)}
						>
							<AccordionHeader className='relative before:absolute before:w-[8px] before:h-[8px] before:bg-leadColor before:top-1/2 before:transform before:-translate-y-1/2 before:-left-5 before:rounded-full ml-5 text-left text-[17px] md:text-lg'>
								Can you let me know how this fryends app work?
							</AccordionHeader>
							<AccordionBody className='ml-5'>
								Lorem Ipsum is simply dummy text of the printing and typesetting
								industry. Lorem Ipsum has been the industry's standard dummy
								text ever since the 1500s
							</AccordionBody>
						</Accordion>
						<Accordion
							open={open === 2}
							icon={<Icon id={2} open={open} />}
							onClick={() => handleOpen(2)}
						>
							<AccordionHeader className='relative before:absolute before:w-[8px] before:h-[8px] before:bg-leadColor before:top-1/2 before:transform before:-translate-y-1/2 before:-left-5 before:rounded-full ml-5 text-left text-[17px] md:text-lg'>
								How can I send friend request on fryends app?
							</AccordionHeader>
							<AccordionBody className='ml-5'>
								Lorem Ipsum is simply dummy text of the printing and typesetting
								industry. Lorem Ipsum has been the industry's standard dummy
								text ever since the 1500s
							</AccordionBody>
						</Accordion>
						<Accordion
							open={open === 3}
							icon={<Icon id={3} open={open} />}
							onClick={() => handleOpen(3)}
						>
							<AccordionHeader className='relative before:absolute before:w-[8px] before:h-[8px] before:bg-leadColor before:top-1/2 before:transform before:-translate-y-1/2 before:-left-5 before:rounded-full ml-5 text-left text-[17px] md:text-lg'>
								Are only friends included in fryends app or professional family
								members can also beincluded?
							</AccordionHeader>
							<AccordionBody className='ml-5'>
								Lorem Ipsum is simply dummy text of the printing and typesetting
								industry. Lorem Ipsum has been the industry's standard dummy
								text ever since the 1500s
							</AccordionBody>
						</Accordion>
						<Accordion
							open={open === 4}
							icon={<Icon id={4} open={open} />}
							onClick={() => handleOpen(4)}
						>
							<AccordionHeader className='relative before:absolute before:w-[8px] before:h-[8px] before:bg-leadColor before:top-1/2 before:transform before:-translate-y-1/2 before:-left-5 before:rounded-full ml-5 text-left text-[17px] md:text-lg'>
								Is this a paid or free app?
							</AccordionHeader>
							<AccordionBody className='ml-5'>
								Lorem Ipsum is simply dummy text of the printing and typesetting
								industry. Lorem Ipsum has been the industry's standard dummy
								text ever since the
							</AccordionBody>
						</Accordion>
					</Fragment>
				</div>
			</div>
		</section>
	);
}

export default Questions;
