import React from "react";
import AppleStore from "../../assets/ApplePlay.svg";
import GoogleStore from "../../assets/GooglePlay.svg";

export default function Hero() {
	return (
		<header className='py-28'>
			<div className='container mx-auto'>
				<div className='text-center'>
					<h2 className='md:text-6xl text-3xl text-headerSubClr lg:w-1/2 mx-auto '>
						The 1st and only private network to...
					</h2>
					<h1 className='font-extrabold md:text-8xl text-6xl text-mainTitle mt-3'>
						Automate <span className='text-leadColor'>Referrals</span>
					</h1>

					<a href='#!' className='inline-block'>
						<p className='bg-leadColor w-28 h-28 shadow-lg rounded-full flex items-center justify-center mt-12 mb-2'>
							<svg
								width='37'
								height='43'
								viewBox='0 0 37 43'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
								className='inline-block'
							>
								<path
									d='M35.1433 25.2933C34.991 25.4961 34.2807 26.3583 33.7226 26.9161L33.4181 27.2204C29.156 31.8355 18.5515 38.7834 13.1732 41.0148C13.1732 41.0655 9.97663 42.3841 8.45446 42.4348H8.2515C5.9175 42.4348 3.73571 41.1163 2.61945 38.9862C2.01058 37.8198 1.45245 34.4219 1.40171 34.3712C0.94506 31.3283 0.640625 26.6676 0.640625 21.5404C0.640625 16.1646 0.94506 11.296 1.50319 8.30386C1.50319 8.25314 2.06132 5.51455 2.4165 4.60169C2.97463 3.2831 3.98941 2.16738 5.25789 1.45738C6.27267 0.950229 7.33819 0.696655 8.45446 0.696655C9.62146 0.74737 11.8032 1.51316 12.6658 1.86309C18.3486 4.09454 29.2068 11.3975 33.3674 15.8603C34.0777 16.5703 34.8388 17.4325 35.0418 17.6354C35.9043 18.7511 36.361 20.1204 36.361 21.5962C36.361 22.9097 35.9551 24.2283 35.1433 25.2933'
									fill='white'
								/>
							</svg>
						</p>

						<p className='uppercase font-semibold text-playTxt'>see how</p>
					</a>

					<div className='flex items-center justify-center md:flex-row space-x-7 mt-20'>
						<a href='#!'>
							<img src={AppleStore} alt='AppleStore' />
						</a>
						<a href='#!'>
							<img src={GoogleStore} alt='GoogleStore' />
						</a>
					</div>
				</div>
			</div>
		</header>
	);
}
