import React from "react";

function Videos() {
	return (
		<section className='py-20'>
			<div className='container mx-auto'>
				<div className='text-center'>
					<h2 className='text-6xl font-semibold mb-8 text-mainTitle'>
						How it works
					</h2>
				</div>

				<div className='relative shadow-lg px-20 py-24 rounded-3xl bg-videoImg bg-cover bg-no-repeat'>
					<div className='absolute top-0 left-0 w-full h-full bg-black/80 rounded-3xl'></div>
					<div className='z-10 relative text-center'>
						<div className='cursor-pointer'>
							<svg
								width='60'
								height='60'
								viewBox='0 0 60 60'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
								className='mx-auto z-10'
							>
								<path
									fillRule='evenodd'
									clipRule='evenodd'
									d='M0 30.0174C0 13.4588 13.4653 0 30 0C46.5347 0 60 13.4588 60 30.0174C60 46.5412 46.5347 60 30 60C13.4653 60 0 46.5412 0 30.0174ZM41.0053 33.0537C41.3238 32.7353 41.7292 32.2432 41.8161 32.1275C42.2794 31.5197 42.5111 30.7671 42.5111 30.0175C42.5111 29.1752 42.2505 28.3938 41.7582 27.757C41.7179 27.7167 41.6389 27.6307 41.5359 27.5186C41.3433 27.309 41.0668 27.008 40.8026 26.744C38.4281 24.1969 32.2312 20.0291 28.9879 18.7555C28.4957 18.5558 27.2505 18.1188 26.5845 18.0898C25.9474 18.0898 25.3393 18.2346 24.7601 18.524C24.0362 18.9292 23.457 19.566 23.1385 20.3185C22.9358 20.8395 22.6173 22.4024 22.6173 22.4314C22.2987 24.139 22.125 26.9176 22.125 29.9856C22.125 32.9118 22.2987 35.5718 22.5594 37.3084C22.5665 37.3155 22.5914 37.4397 22.6301 37.6327C22.7478 38.2193 22.9929 39.4413 23.2543 39.9422C23.8914 41.1579 25.1366 41.9104 26.4686 41.9104H26.5845C27.4532 41.8815 29.2775 41.1289 29.2775 41.1C32.347 39.8265 38.3991 35.8612 40.8316 33.2273L41.0053 33.0537Z'
									fill='url(#paint0_linear_19134_32384)'
								/>
								<defs>
									<linearGradient
										id='paint0_linear_19134_32384'
										x1='30'
										y1='0'
										x2='30'
										y2='60'
										gradientUnits='userSpaceOnUse'
									>
										<stop stopColor='#F1E1C2' />
										<stop offset='1' stopColor='#FCBD99' />
									</linearGradient>
								</defs>
							</svg>
						</div>

						<h2 className='font-semibold text-4xl text-white mt-14 md:w-1/3 mx-auto'>
							The best referrals are word of mouth.
						</h2>
					</div>
				</div>
			</div>
		</section>
	);
}

export default Videos;
