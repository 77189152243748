import React from "react";
import Img1 from "../../assets/BottomeIphone.svg";
import Img2 from "../../assets/BottomApple.svg";
import Img3 from "../../assets/BottomGoogle.svg";

function Discover() {
	return (
		<section className='py-11'>
			<div className='container mx-auto'>
				<div className='flex items-center flex-col md:flex-row bg-gradient-to-r from-[#FCBD99] to-[#F1E1C2] md:px-10 px-5 rounded-3xl pt-10'>
					<div>
						<div className='flex md:flex-row flex-col gap-6'>
							<img src={Img2} alt='Img2' />
							<img src={Img3} alt='Img3' />
						</div>
						<h2 className='md:text-6xl text-4xl font-semibold mb-8 text-white mt-7'>
							Discover even <span className='text-leadColor'>more</span>
						</h2>
					</div>

					<div className='mx-auto'>
						<img src={Img1} alt='Img1' />
					</div>
				</div>
			</div>
		</section>
	);
}

export default Discover;
