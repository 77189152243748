import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Img1 from "../../assets/Slider1.svg";
import Img2 from "../../assets/Slider2.svg";
import Img3 from "../../assets/Slider3.svg";

function Sliders() {
	var settings = {
		arrows: false,
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		// autoplay: true,
		autoplaySpeed: 4000,
	};
	return (
		<section>
			<div className='container mx-auto'>
				<Slider {...settings}>
					<div>
						<div className='flex flex-col md:flex-row bg-white rounded-3xl md:px-20 px-5 mx-4 py-20 md:mx-9'>
							<div className='md:w-1/2'>
								<h2 className='md:text-5xl text-4xl font-semibold mb-8 text-mainTitle'>
									No <span className='text-leadColor'>connection</span> needed
									to any of your social accounts...
								</h2>
								<h2 className='md:text-5xl text-4xl text-allLead'>
									You would really only need your most trusted fryends. likely
									no more than about 50-70...
								</h2>
							</div>

							<div className='md:w-1/2 md:mt-0 mt-10'>
								<img className='mx-auto' src={Img1} alt='' />
							</div>
						</div>
					</div>

					<div>
						<div className='flex flex-col md:flex-row bg-white rounded-3xl md:px-20 px-5 py-20 md:mx-9'>
							<div className='md:w-1/2'>
								<h2 className='md:text-5xl text-4xl font-semibold mb-8 text-mainTitle'>
									You would <span className='text-leadColor'>really only</span>{" "}
									need your most{" "}
									<span className='text-leadColor'>trusted fryends.</span>
								</h2>
								<h2 className='md:text-5xl text-4xl text-allLead'>
									You would really only need your most trusted fryends. likely
									no more than about 50-70...
								</h2>
							</div>

							<div className='md:w-1/2 md:mt-0 mt-10'>
								<img className='mx-auto' src={Img2} alt='' />
							</div>
						</div>
					</div>
					<div>
						<div className='flex flex-col md:flex-row bg-white rounded-3xl md:px-20 px-5 pt-20 mx-4 md:mx-9'>
							<div className='md:w-1/2'>
								<h2 className='md:text-5xl text-4xl font-semibold mb-8 text-mainTitle'>
									We are not just any{" "}
									<span className='text-leadColor'>job marketplace</span>
									not upwork, not fiverr, not thumbtack...
								</h2>
							</div>

							<div className='md:w-1/2 md:mt-0 mt-10'>
								<img className='mx-auto' src={Img3} alt='' />
							</div>
						</div>
					</div>
				</Slider>
			</div>
		</section>
	);
}

export default Sliders;
