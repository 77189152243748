import React from "react";
import Iphone from "../../assets/Iphone.svg";
import World from "../../assets/World.svg";

export default function Subscribe() {
	return (
		<section>
			<div className='container mx-auto'>
				<div className='bg-white shadow-lg rounded-3xl md:px-20 px-4 pt-20 relative'>
					<div className='flex flex-col-reverse md:flex-row items-center'>
						<div className='md:w-[30%] md:mt-0 mt-16'>
							<img src={Iphone} alt='' />
						</div>
						<div className='md:w-[70%]'>
							<h2 className='md:text-6xl text-4xl font-semibold mb-8 text-mainTitle'>
								Your network can work harder,{" "}
								<span className='text-transparent bg-gradient-to-r from-linerOne/100 to-linerTwo/100 bg-clip-text'>
									automate
								</span>{" "}
								it!
							</h2>
							<h2 className='md:text-6xl text-4xl mb-14 text-allLead'>
								The best referrals are from word of mouth, we just automated
								them.
							</h2>
							<a
								href='#!'
								className='inline-block text-ancrClr uppercase font-semibold'
							>
								<p className='flex items-center'>
									SUBSCRIBE{" "}
									<span className='ml-6'>
										<svg
											width='9'
											height='14'
											viewBox='0 0 9 14'
											fill='none'
											xmlns='http://www.w3.org/2000/svg'
										>
											<path
												d='M1.625 1L7.625 7L1.625 13'
												stroke='#5D5FEF'
												strokeWidth='1.5'
												strokeLinecap='round'
												strokeLinejoin='round'
											/>
										</svg>
									</span>
								</p>
							</a>
						</div>
					</div>

					<div className='absolute top-14 right-14'>
						<img src={World} alt='World' />
					</div>
				</div>
			</div>
		</section>
	);
}
